<template>
  <div class="pb-2">
    <l-draggable-table
      ref="lTableRef"
      :table-config-options="tableConfigOptions()"
      :module-name="paymentMethodsModuleName"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-payment-methods-create' }"
      :can-create="ACCESS_ABILITY_FOR_PAYMENT_METHODS"
      :is-searchable="false"
      :is-pagination-able="true"
      trash-list-path="settings-payment-methods-trash-list"
      :can-show-trash-list="ACCESS_ABILITY_FOR_PAYMENT_METHODS"
      @onChoose="onChoose"
    >
      <template #cell(is_active)="{data}">
        <div class="text-nowrap text-right mr-1">
          {{ getStatus(data.is_active).name }}
        </div>
      </template>
      <template #cell(is_default)="{data}">
        <div
          :id="data.id"
          class="d-flex align-items-center justify-content-center w-50"
          style="width: max-content; height: 20px;  "
          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
          @click="setDefault(data)"
        >
          <div class="cursor-pointer">
            <b-form-radio
              :id="data.id.toString()"
              :checked="data.is_default"
              style="pointer-events: none"
              :value="true"
              :disabled="isLoadingOfSetDisabled"
            />
            <b-spinner
              v-if="isSettling(data.id)"
              style="margin-bottom: 4px;"
              class="ml-1"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </div>
        </div>
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_PAYMENT_METHODS.action"
            :a="ACCESS_ABILITY_FOR_PAYMENT_METHODS.subject"
          >
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              :title="$t('View')"
              icon="LStockEyeIcon"
              class="cursor-pointer mr-1"
              size="32"
              @click="gotoNewPage({ name: 'settings-payment-methods-update', params: { id: data.id } }, $event)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover.bottom
              :title="$t('Set Inactive')"
              icon="LTrashIconKits"
              size="32"
              class="cursor-pointer"
              @click="setInactive(data)"
            />
          </Can>
        </div>
      </template>
    </l-draggable-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { BFormRadio, BSpinner, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import LDraggableTable from '@/views/components/LDraggableTable.vue'
import tableConfig from '../config'

export default {
  name: 'PaymentMethodsList',
  components: {
    BSpinner,
    BFormRadio,
    LDraggableTable,
    LTable,
  },
  inject: ['paymentMethodsModuleName'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  computed: {
    ...mapGetters('listModule', ['getStatus']),
  },
  methods: {
    onChoose() {
      this.sendNotification(
        this, { sort_order: this.$refs.lTableRef.$data.items.map(item => item.id) },
        `${this.paymentMethodsModuleName}/setPosition`,
      ).then(() => {
        this.isLoadingOfSetDisabled = false
      })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const id = Number(data.id)
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        id,
        `${this.paymentMethodsModuleName}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    tableConfigOptions() {
      return {
        endpoint: `${this.paymentMethodsModuleName}/getActiveList`,
      }
    },
    refetchData() {
      this.$refs.lTableRef.fetchTableItemsList()
    },
    setInactive(item) {
      this.confirmNotification(this, { id: item.id, is_active: false }, `${this.paymentMethodsModuleName}/update`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'settings-payment-methods'
    const { tableColumns, ACCESS_ABILITY_FOR_PAYMENT_METHODS } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_PAYMENT_METHODS,
    }
  },
}
</script>
