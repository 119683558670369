import {
  CHECKBOX_LEF, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_FOR_PAYMENT_METHODS = { action: 'change', subject: 'Payment Methods' }

  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: can(ACCESS_ABILITY_FOR_PAYMENT_METHODS.action, ACCESS_ABILITY_FOR_PAYMENT_METHODS.subject) ? '10%' : '0%' } },
    { key: 'name', label: 'Name' },
    { key: 'is_active', label: 'Status', thStyle: { 'text-align': 'right' } },
    { key: 'is_default', label: 'Default', thStyle: { width: '20%' } },
  ]

  const fields = {
    name: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
    payment_gateway_id: {
      type: SELECT_INPUT,
      label: 'Integration',
      rules: 'required',
      store: 'integrationsList',
      options: {
        value: 'id',
        label: 'name',
      },
      placeholder: 'Select...',
    },
  }

  return {
    fields,
    tableColumns,
    ACCESS_ABILITY_FOR_PAYMENT_METHODS,
  }
}
